import React, { useEffect, ReactElement, Suspense, useState } from 'react';
import {
  setNetworkErrorHandler,
  setLogoutHandler,
  setTokenGenerator
} from '@api/client';
import { AuthorizationBoundary } from '@components/AuthorizationBoundary';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth';
import TwoStepVerification from '@components/TwoStepVerification';
import InvitationExpired from '@components/InvitationExpired/InvitationExpired';
import LottieComponent from '@components/Lottie';
import { TrialSignUp } from '@pages/TrialSignUp/TrialSignUp';
import ErrorNetworkPage from '@pages/ErrorPage/ErrorNetworkPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

const AuthorizedApp = React.lazy(() => import('src/AuthorizedApp'));
const ExternalReviewer = React.lazy(() => import('@pages/ExternalReviewer'));
const ApprovalReviewer = React.lazy(() => import('@pages/ApprovalReviewer'));
const SlackOAuth = React.lazy(() => import('@pages/OAuth/Slack'));
const AsanaOAuth = React.lazy(() => import('@pages/OAuth/Asana'));
const MondayOAuth = React.lazy(() => import('@pages/OAuth/Monday'));

const NO_RETRY_STATUS = [400, 401, 403, 404];
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > 3) return false;
        if (!isAxiosError(error)) return false;
        const statusCode = error.response?.status ?? 0;
        if (NO_RETRY_STATUS.includes(statusCode)) return false;
        // Do not retry if already retried by Axios
        // See ./src/api/client.ts
        const attempt = (error.config as any)?.attempt ?? 0;
        if (attempt > 0) return false;
        return true;
      }
    }
  }
});

function RenderError({ loader }: { loader: ReactElement }) {
  const { login } = useAuth(false, 'internal');
  useEffect(() => {
    login({
      prompt: 'login',
      appState: { returnTo: window.location.pathname + window.location.search }
    });
  }, []);
  return loader;
}

export function App() {
  const [networkError, setNetworkError] = useState<boolean>(false);
  const { getAccessToken, logout } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    setTokenGenerator(getAccessToken);
    setNetworkErrorHandler(setNetworkError);
    setLogoutHandler(() => logout({ returnTo: window.location.origin }));
  }, [getAccessToken, navigate, logout]);
  const fullPageLoader = (
    <LottieComponent className="page_spinner" view="preloader" />
  );
  if (networkError) return <ErrorNetworkPage />;
  return (
    <Suspense fallback={fullPageLoader}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route
            path="/two-step-verification"
            element={<TwoStepVerification />}
          />
          <Route path="/link-expired" element={<InvitationExpired />} />
          <Route
            path="/external-reviewer/*"
            element={<ExternalReviewer loader={fullPageLoader} />}
          />
          <Route
            path="/approval/*"
            element={<ApprovalReviewer loader={fullPageLoader} />}
          />
          <Route
            path="/trial"
            element={<TrialSignUp loader={fullPageLoader} />}
          />
          <Route
            path="/oauth/slack"
            element={<SlackOAuth loader={fullPageLoader} />}
          />
          <Route
            path="/oauth/asana"
            element={<AsanaOAuth loader={fullPageLoader} />}
          />
          <Route
            path="/oauth/monday"
            element={<MondayOAuth loader={fullPageLoader} />}
          />
          <Route
            path="/*"
            element={
              <AuthorizationBoundary
                element={<AuthorizedApp loader={fullPageLoader} />}
                loader={fullPageLoader}
                renderError={() => <RenderError loader={fullPageLoader} />}
              />
            }
          />
        </Routes>
      </QueryClientProvider>
    </Suspense>
  );
}
