import {
  AssetItemDto,
  AssetVersionItemDto,
  AssetVersionCommentDto
} from '@api/Api';

export type ShapesDrawingToolsProps = {
  color: string;
  shape: 'pin' | 'arrow' | 'rectangle' | 'line' | 'ellipse' | 'brush' | 'text';
  mode: 'edit' | 'draw';
};

export interface IMediaViewerState {
  assets: AssetItemDto | null;
  folder: AssetItemDto | null;
  selectedVersion: AssetVersionItemDto | null;
  shapesDrawingTools: ShapesDrawingToolsProps;
  commentsList: AssetVersionCommentDto[];
  selectedCommentId: string | null;
  canvasMode: 'view' | 'edit';
  isSelectedCanvasObject: boolean;
  playerTime: (number | null)[];
  activePageNumber: number;
  zoomFactor: number;
  turnoverDegree: number;
  commentText: string | null;
  isTimecodeChecked: boolean;
  isToolsExpanded: boolean;
  timeFormat: 'standard' | 'frames';
  isSourceSelected: boolean;
  isCommentFieldFocused: boolean;
}

export interface IMediaViewerAction {
  type: string;
  payload?: any;
}

export enum MediaViewerActionTypes {
  SET_ASSET = 'SET_ASSET',
  SET_ASSET_FOLDER = 'SET_ASSET_FOLDER',
  TOGGLE_ASSET_FAVORITE = 'TOGGLE_ASSET_FAVORITE',
  RENAME_ASSET_VERSION = 'RENAME_ASSET_VERSION',
  SET_SELECTED_VERSION = 'SET_SELECTED_VERSION',
  UPDATE_SELECTED_VERSION = 'UPDATE_SELECTED_VERSION',
  MAKE_AW_DECISION = 'MAKE_AW_DECISION',

  SET_TOOLS_EXPANDED = 'SET_TOOLS_EXPANDED',
  SET_DRAW_TOOLS_COLOR = 'SET_DRAW_TOOLS_COLOR',
  SET_DRAW_TOOLS_SHAPE = 'SET_DRAW_TOOLS_SHAPE',
  SET_DRAW_TOOLS_MODE = 'SET_DRAW_TOOLS_MODE',

  SELECT_COMMENT = 'SELECT_COMMENT',
  SET_CANVAS_MODE = 'SET_CANVAS_MODE',
  SET_SELECTED_CANVAS_OBJECT = 'SET_SELECTED_CANVAS_OBJECT',
  SET_PLAYER_TIME = 'SET_PLAYER_TIME',
  SET_ACTIVE_PAGE_NUMBER = 'SET_ACTIVE_PAGE_NUMBER',
  SET_ZOOM_FACTOR = 'SET_ZOOM_FACTOR',
  SET_TURNOVER_DEGREE = 'SET_TURNOVER_DEGREE',
  SET_COMMENT_TEXT = 'SET_COMMENT_TEXT',
  SET_IS_TIMECODE_CHECKED = 'SET_IS_TIMECODE_CHECKED',
  SET_TIME_FORMAT = 'SET_TIME_FORMAT',
  SET_IS_SOURCE_SELECTED = 'SET_IS_SOURCE_SELECTED',
  SET_IS_COMMENT_FIELD_FOCUSED = 'SET_IS_COMMENT_FIELD_FOCUSED',

  GET_ASSET_INTERNAL_COMMENTS = 'GET_ASSET_INTERNAL_COMMENTS',
  CREATE_ASSET_INTERNAL_COMMENT = 'CREATE_ASSET_INTERNAL_COMMENT',
  DELETE_ASSET_INTERNAL_COMMENT = 'DELETE_ASSET_INTERNAL_COMMENT',
  UPDATE_ASSET_INTERNAL_COMMENT = 'UPDATE_ASSET_INTERNAL_COMMENT',
  SET_ASSET_INTERNAL_COMMENT_LIKE = 'SET_ASSET_INTERNAL_COMMENT_LIKE',

  SET_RESET_ASSET_STATE = 'SET_RESET_ASSET_STATE',
  SET_RESET_ASSET_VERSION_STATE = 'SET_RESET_ASSET_VERSION_STATE'
}
