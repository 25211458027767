import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MixpanelProvider } from 'react-mixpanel-browser';
import store from '@redux/store';
import { enablePatches } from 'immer';
import Auth0ProviderWithHistory from '@hoc';
import { AuthProvider } from '@context/AuthContext';
import { API_BASE_URL, MIXPANEL_KEY } from '@config/constants';
import { registerServiceWorker } from 'src/register-sw';
import { CurrentTimeProvider } from '@context/CurrentTimeContext';
import { datadogLogs } from '@datadog/browser-logs';
import { ErrorBoundary } from '@pages/ErrorPage';
import { APP_ENV, APP_VERSION } from '@helpers/appEnv';
import { nonNull } from '@helpers/non-null';
import { App } from './App';
import '@styles/main.less';

if (APP_VERSION && APP_VERSION !== 'unknown') {
  datadogLogs.init({
    service: 'frontend',
    env: APP_ENV,
    version: APP_VERSION,
    clientToken: nonNull(
      process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      'REACT_APP_DATADOG_CLIENT_TOKEN'
    ),
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    trackSessionAcrossSubdomains: true,
    sessionSampleRate: 100,
    proxy: `${API_BASE_URL}dtdg`
  });
}

registerServiceWorker();

enablePatches();

const originalPushState = window.history.pushState.bind(window.history);
const originalReplaceState = window.history.replaceState.bind(window.history);
function isSameURL(url?: string | URL | null) {
  if (url == null) return null;
  if (typeof url === 'string') {
    const aEl = document.createElement('a');
    aEl.href = url;
    return aEl.href === window.location.href;
  }
  return url.toString() === window.location.href;
}
window.history.pushState = (state, title, url) => {
  if (!isSameURL(url)) {
    originalPushState(state, title, url);
  }
};
window.history.replaceState = (state, title, url) => {
  if (!isSameURL(url)) {
    originalReplaceState(state, title, url);
  }
};

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <MixpanelProvider
        token={MIXPANEL_KEY}
        config={{
          api_host: `${API_BASE_URL}mxpnl`,
          api_payload_format: 'json'
        }}
      >
        <Provider store={store}>
          <Auth0ProviderWithHistory>
            <AuthProvider>
              <CurrentTimeProvider>
                <App />
              </CurrentTimeProvider>
            </AuthProvider>
          </Auth0ProviderWithHistory>
        </Provider>
      </MixpanelProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
);
