import { AssetItemDto, AssetVersionItemDto } from '@api/Api';
import {
  IMediaViewerState,
  MediaViewerActionTypes,
  IMediaViewerAction
} from '@redux/types/mediaViewerType';

const INITIAL_STATE: IMediaViewerState = {
  assets: null,
  folder: null,
  selectedVersion: null,
  shapesDrawingTools: {
    color: '#58c97a',
    shape: 'brush',
    mode: 'draw'
  },
  commentsList: [],
  selectedCommentId: null,
  canvasMode: 'view',
  isSelectedCanvasObject: false,
  playerTime: [],
  activePageNumber: 1,
  zoomFactor: 1,
  turnoverDegree: 0,
  commentText: null,
  isTimecodeChecked: true,
  isToolsExpanded: false,
  timeFormat: 'standard',
  isSourceSelected: false,
  isCommentFieldFocused: false
};

const mediaViewerReducer = (
  state = INITIAL_STATE,
  action: IMediaViewerAction
): IMediaViewerState => {
  switch (action.type) {
    case MediaViewerActionTypes.SET_ASSET: {
      return {
        ...state,
        assets: action.payload
      };
    }
    case MediaViewerActionTypes.TOGGLE_ASSET_FAVORITE: {
      if (!state.assets) return state;
      const { isFavorite } = action.payload;
      const assets = { ...state.assets, isFavorite };
      return {
        ...state,
        assets
      };
    }
    case MediaViewerActionTypes.SET_TIME_FORMAT: {
      const { timeFormat } = action.payload;
      return {
        ...state,
        timeFormat
      };
    }
    case MediaViewerActionTypes.SET_IS_SOURCE_SELECTED: {
      const { isSourceSelected } = action.payload;
      return {
        ...state,
        isSourceSelected
      };
    }
    case MediaViewerActionTypes.MAKE_AW_DECISION: {
      const { asset } = action.payload;
      if (!state.assets?.asset || !state.selectedVersion) return state;
      const selectedVersion = asset.asset?.versions.find(
        (el: AssetVersionItemDto) => el.id === state.selectedVersion?.id
      );
      const assets = {
        ...state.assets,
        asset: {
          ...state.assets.asset,
          versions: state.assets.asset.versions.map((it) => {
            if (it.id === selectedVersion.id) return selectedVersion;
            return it;
          })
        }
      };
      return { ...state, selectedVersion, assets };
    }
    case MediaViewerActionTypes.RENAME_ASSET_VERSION: {
      const { name, assetVersionId } = action.payload;
      if (!state.assets?.asset?.versions) return state;
      const newVersions = state.assets.asset.versions.map((el) => {
        if (el.id === assetVersionId) return { ...el, name };
        return el;
      });
      const assets = {
        ...state.assets,
        asset: { ...state.assets.asset, versions: newVersions }
      };
      const selectedVersion = state.selectedVersion
        ? {
            ...state.selectedVersion,
            name
          }
        : null;
      return {
        ...state,
        assets,
        selectedVersion
      };
    }
    case MediaViewerActionTypes.SET_ASSET_FOLDER: {
      return {
        ...state,
        folder: action.payload
      };
    }
    case MediaViewerActionTypes.SET_SELECTED_VERSION: {
      const { assets } = state;
      const selectedVersion =
        assets?.asset?.versions.find((item) => item.id === action.payload.id) ||
        null;
      return {
        ...state,
        selectedVersion
      };
    }
    case MediaViewerActionTypes.UPDATE_SELECTED_VERSION: {
      const { assets } = state;
      const { newSelectedVersion } = action.payload;
      const updateAssetVersions: AssetVersionItemDto[] =
        assets?.asset?.versions?.map((version) => {
          if (version.id === newSelectedVersion.id) {
            version = newSelectedVersion;
          }
          return version;
        }) || [];
      return {
        ...state,
        assets: {
          ...assets,
          asset: {
            ...assets?.asset,
            versions: updateAssetVersions
          }
        } as AssetItemDto,
        selectedVersion: action.payload.newSelectedVersion
      };
    }
    case MediaViewerActionTypes.SET_PLAYER_TIME: {
      return {
        ...state,
        playerTime: action.payload
      };
    }
    case MediaViewerActionTypes.SET_ACTIVE_PAGE_NUMBER: {
      return {
        ...state,
        activePageNumber: action.payload
      };
    }
    case MediaViewerActionTypes.SET_CANVAS_MODE: {
      return {
        ...state,
        canvasMode: action.payload
      };
    }
    case MediaViewerActionTypes.SET_SELECTED_CANVAS_OBJECT: {
      return {
        ...state,
        isSelectedCanvasObject: action.payload
      };
    }
    // COMMENTS
    case MediaViewerActionTypes.SELECT_COMMENT: {
      return {
        ...state,
        selectedCommentId: action.payload
      };
    }

    case MediaViewerActionTypes.CREATE_ASSET_INTERNAL_COMMENT: {
      return {
        ...state,
        commentsList: [action.payload, ...state.commentsList]
      };
    }
    case MediaViewerActionTypes.UPDATE_ASSET_INTERNAL_COMMENT: {
      const commentsList = state.commentsList.map((comment) => {
        if (comment.id === action.payload.id)
          return {
            ...comment,
            ...action.payload
          };
        return comment;
      });
      return {
        ...state,
        commentsList
      };
    }
    case MediaViewerActionTypes.SET_ASSET_INTERNAL_COMMENT_LIKE: {
      const { params, user } = action.payload;
      let newCommentList = state.commentsList;
      if (params.value) {
        newCommentList = newCommentList.map((el: any) => {
          if (el.id === params.id)
            return {
              ...el,
              likes: [...el.likes, user]
            };
          return el;
        });
      } else {
        newCommentList = newCommentList.map((el) => {
          if (el.id === params.id)
            return {
              ...el,
              likes: el.likes.filter((el: any) => !el.me)
            };
          return el;
        });
      }
      return {
        ...state,
        commentsList: newCommentList
      };
    }
    case MediaViewerActionTypes.DELETE_ASSET_INTERNAL_COMMENT: {
      const commentsList = state.commentsList.filter(
        (comment) =>
          comment.id !== action.payload &&
          comment.topParentId !== action.payload
      );
      return {
        ...state,
        commentsList
      };
    }

    case MediaViewerActionTypes.SET_TOOLS_EXPANDED: {
      return {
        ...state,
        isToolsExpanded: action.payload
      };
    }
    case MediaViewerActionTypes.SET_DRAW_TOOLS_COLOR: {
      return {
        ...state,
        shapesDrawingTools: {
          ...state.shapesDrawingTools,
          color: action.payload
        }
      };
    }
    case MediaViewerActionTypes.SET_DRAW_TOOLS_SHAPE: {
      return {
        ...state,
        shapesDrawingTools: {
          ...state.shapesDrawingTools,
          shape: action.payload
        }
      };
    }
    case MediaViewerActionTypes.SET_DRAW_TOOLS_MODE: {
      return {
        ...state,
        shapesDrawingTools: {
          ...state.shapesDrawingTools,
          mode: action.payload
        }
      };
    }
    case MediaViewerActionTypes.GET_ASSET_INTERNAL_COMMENTS: {
      return {
        ...state,
        commentsList: action.payload
      };
    }
    case MediaViewerActionTypes.SET_IS_COMMENT_FIELD_FOCUSED: {
      return { ...state, isCommentFieldFocused: action.payload };
    }
    // RESET AFTER UPDATE ASSET ID
    case MediaViewerActionTypes.SET_RESET_ASSET_STATE: {
      return {
        ...state,
        folder: null,
        assets: null,
        selectedVersion: null,
        selectedCommentId: null,
        canvasMode: 'view',
        commentsList: [],
        activePageNumber: 1,
        zoomFactor: 1,
        isToolsExpanded: false,
        turnoverDegree: 0
      };
    }
    case MediaViewerActionTypes.SET_RESET_ASSET_VERSION_STATE: {
      return {
        ...state,
        canvasMode: 'view',
        commentsList: [],
        activePageNumber: 1,
        zoomFactor: 1,
        isToolsExpanded: false,
        turnoverDegree: 0,
        shapesDrawingTools: {
          color: '#58c97a',
          shape: 'brush',
          mode: 'draw'
        }
      };
    }
    case MediaViewerActionTypes.SET_ZOOM_FACTOR: {
      const { zoomFactor } = action.payload;
      return {
        ...state,
        zoomFactor
      };
    }
    case MediaViewerActionTypes.SET_TURNOVER_DEGREE: {
      const { turnoverDegree } = action.payload;
      return {
        ...state,
        turnoverDegree
      };
    }
    case MediaViewerActionTypes.SET_COMMENT_TEXT: {
      const { commentText } = action.payload;
      return {
        ...state,
        commentText
      };
    }
    case MediaViewerActionTypes.SET_IS_TIMECODE_CHECKED: {
      const { isTimecodeChecked } = action.payload;
      return {
        ...state,
        isTimecodeChecked
      };
    }
    default: {
      return state;
    }
  }
};

export default mediaViewerReducer;
